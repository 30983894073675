import request from "@/utils/request";

export const CreateExcel = function () {
  return request({
    url: "/CreateExcel/creatExcel",
    method: "GET",
  });
};

export const ViewExcel = function (params) {
  return request({
    url: "/SimpleExcel/Excel",
    method: "get",
    params,
  });
};

export const simpleMexcel = function (params) {
  return request({
    url: "/CreateMexcel/simpleMexcel",
    method: "GET",
    params,
  });
};
export const editExcel = function (params) {
  return request({
    url: "/SimpleFinalExcel/editExcel",
    method: "GET",
    params,
  });
};

export const SimpleFinalExcel_Excel = (params) => {
  return request({
    url: "/SimpleFinalExcel/Excel",
    method: "GET",
    params,
  });
};
export const SimpleFinalExcel_savaPDF = (params) => {
  return request({
    url: "/SimpleFinalExcel/SimplePDF",
    method: "GET",
    params,
  });
};

export const continueEditorExcel = function (params) {
  return request({
    url: "/SimpleFinalExcel/editFillExcel",
    method: "GET",
    params,
  });
};

///SimpleExcel/readOnly
export const SimpleExcelreadOnly = function (params) {
  return request({
    url: "/SimpleExcel/readOnly",
    method: "GET",
    params,
  });
};

///SimpleFinalExcel/readOnly
export const SimpleFinalExcelreadOnly = function (params) {
  return request({
    url: "/SimpleFinalExcel/readOnly",
    method: "GET",
    params,
  });
};

// /DataRegionEdit/simpleWord

export const simpleWord = function (params) {
  return request({
    url: "/DataRegionEdit/simpleWord",
    method: "GET",
    params,
  });
};
